import Icon from '@lyra/core/components/Icon'
import QuestionPixelIcon from '@lyra/core/components/Icon/QuestionPixelIcon'
import { MARKET_ICON_SIZE } from '@lyra/web/constants/layout'
import { CurrencyId } from '@lyra/web/constants/tokens'
import Image from 'next/image'
import React from 'react'

type Props = {
  marketId: CurrencyId
  size?: number
}

const getIcon = (tokenId: CurrencyId) => {
  switch (tokenId.toLowerCase()) {
    case 'btc':
      return '/images/tokens/btc.png'
    case 'wbtc':
      return '/images/tokens/wbtc.png'
    case 'eth':
      return '/images/tokens/eth.png'
    case 'weth':
      return '/images/tokens/weth.png'
    case 'usdc.e':
    case 'usdc':
      return '/images/tokens/usdc.png'
    case 'usde':
      return '/images/tokens/USDE.png'
    case 'susde':
      return '/images/tokens/susde.png'
    case 'lyra':
      return '/images/tokens/lyra.png'
    case 'op':
      return '/images/tokens/op.png'
    case 'usdt':
      return '/images/tokens/usdt.png'
    case 'wsteth':
      return '/images/tokens/wstETH.png'
    case 'dai':
      return '/images/tokens/dai.png'
    case 'sdai':
      return '/images/tokens/sdai.png'
    case 'rsweth':
      return '/images/tokens/rsweth.webp'
    case 'weeth':
      return '/images/tokens/weETH.png'
    case 'rseth':
      return '/images/tokens/rseth.png'
    case 'lbtc':
      return '/images/tokens/lbtc.png'
    case 'cbbtc':
      return '/images/tokens/cbbtc.webp'
    case 'ebtc':
      return '/images/tokens/eBTC.png'
    case 'sol':
      return '/images/tokens/sol.png'
    case 'doge':
      return '/images/tokens/doge.png'
    case 'aave':
      return '/images/tokens/aave.png'
    case 'arb':
      return '/images/tokens/arb.png'
    case 'bnb':
      return '/images/tokens/bnb.png'
    case 'near':
      return '/images/tokens/near.png'
    case 'pepe':
      return '/images/tokens/pepe.png'
    case 'sui':
      return '/images/tokens/sui.png'
    case 'tia':
      return '/images/tokens/tia.png'
    case 'wif':
      return '/images/tokens/wif.png'
    case 'wld':
      return '/images/tokens/wld.png'
  }
}

export default function MarketIcon({
  marketId: tokenId,
  size = MARKET_ICON_SIZE,
}: Props): React.ReactNode {
  const icon = getIcon(tokenId)
  if (!icon) {
    return <Icon size={size} icon={<QuestionPixelIcon />} />
  }
  return (
    <Image width={size} height={size} alt={tokenId} src={icon} style={{ borderRadius: 100000 }} />
  )
}
