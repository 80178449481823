'use client'

import fetchAssets from '@lyra/core/api/fetchAssets'
import createSubaccountApi from '@lyra/core/api/private/createSubaccount'
import depositApi from '@lyra/core/api/private/deposit'
import fetchCancelQuote from '@lyra/core/api/private/fetchCancelQuote'
import fetchCancelRfq from '@lyra/core/api/private/fetchCancelRfq'
import fetchExecuteRfq from '@lyra/core/api/private/fetchExecuteRfq'
import fetchSendQuote from '@lyra/core/api/private/fetchSendQuote'
import fetchSendRfq from '@lyra/core/api/private/fetchSendRfq'
import liquidate from '@lyra/core/api/private/liquidate'
import replace from '@lyra/core/api/private/replace'
import sendCancel from '@lyra/core/api/private/sendCancel'
import sendCancelTriggerOrder from '@lyra/core/api/private/sendCancelTriggerOrder'
import sendOrder from '@lyra/core/api/private/sendOrder'
import transferERC20 from '@lyra/core/api/private/transferERC20'
import transferPosition from '@lyra/core/api/private/transferPosition'
import withdrawApi from '@lyra/core/api/private/withdraw'
import {
  PrivateCancelParamsSchema,
  PrivateCancelResultSchema,
} from '@lyra/core/api/types/private.cancel'
import {
  PrivateCancelQuoteParamsSchema,
  PrivateCancelQuoteResultSchema,
} from '@lyra/core/api/types/private.cancel_quote'
import { PrivateCancelRfqParamsSchema, Result } from '@lyra/core/api/types/private.cancel_rfq'
import {
  PrivateCancelTriggerOrderParamsSchema,
  PrivateCancelTriggerOrderResultSchema,
} from '@lyra/core/api/types/private.cancel_trigger_order'
import {
  PrivateExecuteQuoteParamsSchemaNoSignature,
  PrivateExecuteQuoteResultSchema,
} from '@lyra/core/api/types/private.execute_quote'
import {
  PrivateLiquidateParamsSchema,
  PrivateLiquidateResultSchema,
} from '@lyra/core/api/types/private.liquidate'
import {
  PrivateReplaceParamsSchema,
  PrivateReplaceResultSchema,
} from '@lyra/core/api/types/private.replace'
import { PrivateSendQuoteResultSchema } from '@lyra/core/api/types/private.send_quote'
import {
  PrivateSendRfqParamsSchema,
  PrivateSendRfqResultSchema,
} from '@lyra/core/api/types/private.send_rfq'
import { PrivateTransferPositionParamsSchema } from '@lyra/core/api/types/private.transfer_position'
import BodyText from '@lyra/core/components/BodyText'
import { HelpCircle } from '@lyra/core/components/Icon'
import { WEI_DECIMALS } from '@lyra/core/constants/contracts'
import { SECONDS_IN_DAY, SECONDS_IN_HOUR } from '@lyra/core/constants/time'
import { bigNumberToString } from '@lyra/core/utils/bigNumberToString'
import toBigNumber from '@lyra/core/utils/toBigNumber'
import { PrivateSendQuoteParamsSchemaNoSignature } from '@lyra/web/constants/rfqs'
import { ethers } from 'ethers'
import React, { useCallback, useMemo } from 'react'
import useSWR, { KeyedMutator } from 'swr'
import { YStack } from 'tamagui'
import { encodeAbiParameters, Hash, PrivateKeyAccount } from 'viem'

import MarketIcon from '../components/common/MarketIcon'
import { lyraChain } from '../constants/chains'
import { lyraContractAddresses, NEW_MANAGER_ADDRESS } from '../constants/contracts'
import { PartialTickerForSigning } from '../constants/instruments'
import { LOCAL_STORAGE_SUBACCOUNT_ID_KEY } from '../constants/localStorage'
import { MarketId } from '../constants/markets'
import { Order, OrderParamsWithoutSignature } from '../constants/order'
import {
  CreateSubaccountParams,
  DepositSubaccountParams,
  EMPTY_SUBACCOUNT_DATA,
  EmptySubaccountData,
  Subaccount,
  SubaccountData,
} from '../constants/subaccount'
import { CollateralId, TokenId } from '../constants/tokens'
import { TransactionOptions } from '../constants/transactions'
import { User } from '../constants/user'
import useLocalStorage from '../hooks/local_storage/useLocalStorage'
import useAuth from '../hooks/useAuth'
import useOrderbookTimestamp from '../hooks/useOrderbookTimestamp'
import useToast from '../hooks/useToast'
import useTransaction from '../hooks/useTransaction'
import {
  signAction,
  signLiquidateQuote,
  signOrder,
  signRfqQuote,
  signSendQuote,
} from '../utils/actions'
import { signDeposit } from '../utils/client/deposits'
import { signSessionKeyHeaders } from '../utils/client/sessionKey'
import emptyFunction from '../utils/emptyFunction'
import getAuthHeaders from '../utils/getAuthHeaders'
import {
  formatInstrumentWithSize,
  parseInstrumentName,
  parseMarketFromInstrumentName,
} from '../utils/instruments'
import { recordSpindlEvent } from '../utils/spindl'
import {
  fetchSubaccounts,
  getSubaccountData,
  getSubaccountMap,
  updateSubaccountLabel,
  waitForNewSubaccountId,
} from '../utils/subaccounts'
import { getUtcNowSecs } from '../utils/time'
import {
  formatCollateralBalance,
  formatTokenBalance,
  formatTokenSymbol,
  getCollateralAddress,
  getCollateralForMarket,
  getTokenDecimals,
  getTokenForCollateral,
} from '../utils/tokens'
import { coerce } from '../utils/types'
import waitForTransaction from '../utils/waitForTransaction'

type Props = {
  initialSubaccounts: Subaccount[]
  children?: React.ReactNode
}

export type SubaccountContext = {
  isLoading: boolean
  subaccountData?: SubaccountData
  subaccounts: Record<number, Subaccount>
  subaccountDatas: Record<number, SubaccountData>
  deposit: (
    subaccountId: number,
    token: TokenId,
    amount: bigint,
    options: TransactionOptions
  ) => Promise<Hash>
  createSubaccount: (
    params: CreateSubaccountParams,
    options: TransactionOptions
  ) => Promise<{ sessionKey: PrivateKeyAccount; subaccountId: number }>
  updateLabel: (subaccountId: number, label: string) => Promise<void>
  withdraw: (
    subaccountId: number,
    collateral: CollateralId,
    amount: bigint,
    options: TransactionOptions
  ) => Promise<Hash>
  submitOrder: (
    ticker: PartialTickerForSigning,
    params: OrderParamsWithoutSignature,
    options?: { disableToast?: boolean }
  ) => Promise<Order>
  submitAuctionBid: (
    subaccountId: number,
    cashTransfer: string,
    lastSeenTradeId: number,
    liquidatedSubaccountId: number,
    percentBid: string,
    priceLimit: string
  ) => Promise<PrivateLiquidateResultSchema>
  cancelOrder: (
    orderId: string,
    instrumentName: string,
    options?: { disableToast?: boolean }
  ) => Promise<PrivateCancelResultSchema>
  cancelTriggerOrder: (
    orderId: string,
    instrumentName: string,
    options?: { disableToast?: boolean }
  ) => Promise<PrivateCancelTriggerOrderResultSchema>
  executeRfq: (
    tickers: Record<string, PartialTickerForSigning>,
    params: PrivateExecuteQuoteParamsSchemaNoSignature
  ) => Promise<PrivateExecuteQuoteResultSchema>
  sendRfq: (params: PrivateSendRfqParamsSchema) => Promise<PrivateSendRfqResultSchema>
  cancelRfq: (params: PrivateCancelRfqParamsSchema) => Promise<Result>
  sendRfqQuote: (
    tickers: Record<string, PartialTickerForSigning>,
    params: PrivateSendQuoteParamsSchemaNoSignature
  ) => Promise<PrivateSendQuoteResultSchema>
  cancelRfqQuote: (
    params: PrivateCancelQuoteParamsSchema
  ) => Promise<PrivateCancelQuoteResultSchema>
  transfer: (
    senderSubaccountId: number,
    receiverSubaccountId: number,
    collateral: CollateralId,
    amount: string,
    options: TransactionOptions
  ) => Promise<Hash>
  transferPositionToSubaccount: (
    senderSubaccountId: number,
    receiverSubaccountId: number,
    instrumentName: string,
    amount: string,
    isLong: boolean,
    limitPrice: string,
    ticker: PartialTickerForSigning,
    options: TransactionOptions
  ) => Promise<void>
  replaceOrder: (
    orderIdToCancel: string,
    ticker: PartialTickerForSigning,
    params: OrderParamsWithoutSignature
  ) => Promise<PrivateReplaceResultSchema>
  switchSubaccount: (subaccountId: number) => void
  mintTestnetUsdcAndDeposit: (subaccountId: number, options: TransactionOptions) => Promise<Hash>
  mutate: KeyedMutator<Record<number, Subaccount>>
  totalAccountValue: number
  totalUsdcBalance: number
} & (SubaccountData | EmptySubaccountData)

export const SubaccountContext = React.createContext<SubaccountContext>({
  ...EMPTY_SUBACCOUNT_DATA,
  isLoading: false,
  totalAccountValue: 0,
  totalUsdcBalance: 0,
  subaccounts: {},
  subaccountDatas: {},
  deposit: emptyFunction as any,
  createSubaccount: emptyFunction as any,
  updateLabel: emptyFunction as any,
  withdraw: emptyFunction as any,
  submitOrder: emptyFunction as any,
  submitAuctionBid: emptyFunction as any,
  cancelOrder: emptyFunction as any,
  cancelTriggerOrder: emptyFunction as any,
  executeRfq: emptyFunction as any,
  sendRfq: emptyFunction as any,
  sendRfqQuote: emptyFunction as any,
  cancelRfqQuote: emptyFunction as any,
  cancelRfq: emptyFunction as any,
  transfer: emptyFunction as any,
  transferPositionToSubaccount: emptyFunction as any,
  switchSubaccount: emptyFunction as any,
  mintTestnetUsdcAndDeposit: emptyFunction as any,
  replaceOrder: emptyFunction as any,
  mutate: emptyFunction as any,
})

const POLL_INTERVAL = 15_000 // 15 seconds

const fetcher = async (user: User) => {
  const subaccounts = await fetchSubaccounts(user.address, getAuthHeaders(user))
  return getSubaccountMap(subaccounts)
}

export default function SubaccountProvider({
  initialSubaccounts: initialSubaccountsArray,
  children,
}: Props) {
  const { user } = useAuth()
  const { getUtcTimestampSec } = useOrderbookTimestamp()

  const [localSubaccountId, setLocalSubaccountId] = useLocalStorage(LOCAL_STORAGE_SUBACCOUNT_ID_KEY)

  const initialSubaccounts = useMemo(
    () => getSubaccountMap(initialSubaccountsArray),
    [initialSubaccountsArray]
  )

  const {
    data: subaccounts,
    isLoading,
    mutate,
  } = useSWR(['Subaccounts', user], ([_, user]) => (user ? fetcher(user) : []), {
    keepPreviousData: !!user,
    fallbackData: initialSubaccounts,
    refreshInterval: POLL_INTERVAL,
  })

  const totalAccountValue = useMemo(
    () => Object.values(subaccounts).reduce((sum, sub) => sum + +sub.subaccount_value, 0),
    [subaccounts]
  )
  // Used for USDC "vault"
  const totalUsdcBalance = useMemo(
    () =>
      Object.values(subaccounts).reduce(
        (sum, sub) =>
          sum + +(sub.collaterals.find((c) => c.asset_name === CollateralId.USDC)?.amount ?? 0),
        0
      ),
    [subaccounts]
  )

  const selectedSubaccountId =
    !isNaN(+localSubaccountId) && !!subaccounts[+localSubaccountId]
      ? +localSubaccountId
      : Object.keys(subaccounts).length
      ? Object.values(subaccounts)[0].subaccount_id
      : initialSubaccountsArray.length
      ? initialSubaccountsArray[0].subaccount_id
      : undefined

  const { createToast } = useToast()
  const { sessionKey, mintTestnetUsdc, mutateBalances, tryEnableTradingAccount } = useTransaction()

  const subaccountDatas: Record<number, SubaccountData> = useMemo(() => {
    return Object.values(subaccounts).reduce(
      (dict, sub) => ({
        ...dict,
        [sub.subaccount_id]: getSubaccountData(
          sub,
          Object.values(subaccounts).map((sub) => sub.subaccount_id)
        ),
      }),
      {} as Record<number, SubaccountData>
    )
  }, [subaccounts])

  const subaccountData =
    selectedSubaccountId && selectedSubaccountId in subaccountDatas
      ? subaccountDatas[selectedSubaccountId]
      : undefined

  const updateLabel = useCallback(
    async (subaccountId: number, label: string) => {
      if (!user) {
        throw new Error('Not authenticated')
      }

      if (!subaccounts[subaccountId]) {
        throw new Error('User does not own subaccount')
      }

      const authHeaders = getAuthHeaders(user)
      await updateSubaccountLabel(subaccountId, label, authHeaders)

      await mutate()
    },
    [mutate, subaccounts, user]
  )

  const createSubaccount = useCallback(
    async (
      params: CreateSubaccountParams,
      options: TransactionOptions
    ): Promise<{ sessionKey: PrivateKeyAccount; subaccountId: number }> => {
      if (!Object.values(subaccounts).length) {
        throw new Error(
          'You must create and fund your first standard margin subaccount before creating additional subaccounts.'
        )
      }

      const { sessionKey, user } = await tryEnableTradingAccount({
        ...options,
        createSubaccount: false,
      })

      const { marginType, market, label } = params

      options.onTransactionStatusChange('in-progress', {
        title: 'Creating Subaccount',
      })

      const managerAddress =
        marginType === 'PM'
          ? lyraContractAddresses.portfolioManager[market]
          : lyraContractAddresses.standardManager

      if (!managerAddress) {
        throw new Error(`Margin type ${marginType} is not supported in market ${market}`)
      }

      const encodedData = encodeAbiParameters(
        [{ type: 'uint256' }, { type: 'address' }, { type: 'address' }],
        [BigInt(0), getCollateralAddress(CollateralId.USDC), managerAddress]
      )

      const signatureExpirySec = getUtcNowSecs() + SECONDS_IN_HOUR
      const { signature, signatureExpiry, signer, nonce } = await signAction({
        chainId: lyraChain.id,
        signingAccount: sessionKey,
        owner: user.address,
        subaccountId: 0,
        encodedData: encodedData,
        contract: 'deposit',
        signatureExpiry: signatureExpirySec,
      })

      const currency = marginType === 'PM' ? getCollateralForMarket(market) : undefined

      if (marginType === 'PM' && !currency) {
        throw new Error(`No collateral for market ${market}`)
      }

      const createSubaccountParams = {
        amount: String(0),
        asset_name: CollateralId.USDC, // usdc does not matter here
        currency,
        margin_type: marginType,
        nonce,
        signature,
        signature_expiry_sec: signatureExpiry,
        signer,
        wallet: user.address,
      }

      const sessionKeyHeaders = await signSessionKeyHeaders(user.address, sessionKey)
      const res = await createSubaccountApi(createSubaccountParams, sessionKeyHeaders)
      const txId = res.result.transaction_id

      await waitForTransaction(txId)

      const authHeaders = getAuthHeaders(user)

      const subaccountIds = Object.values(subaccounts).map((s) => s.subaccount_id)
      const newSubaccountId = await waitForNewSubaccountId(user.address, subaccountIds, authHeaders)
      if (!newSubaccountId) {
        throw new Error('Subaccount was not registered with orderbook')
      }

      if (!localSubaccountId || initialSubaccountsArray.length === 0) {
        setLocalSubaccountId(newSubaccountId.toString())
      }

      if (label) {
        await updateSubaccountLabel(newSubaccountId, label, authHeaders)
      }

      await mutate()

      if (!options.skipCompleteStatus) {
        options.onTransactionStatusChange('complete', {
          title: 'Successfully Created Subaccount',
        })
      }

      return {
        sessionKey,
        subaccountId: newSubaccountId,
      }
    },
    [
      subaccounts,
      tryEnableTradingAccount,
      localSubaccountId,
      initialSubaccountsArray.length,
      mutate,
      setLocalSubaccountId,
    ]
  )

  /**
   * Deposit tokens into subaccount
   */
  const deposit = useCallback(
    async (subaccountId: number, token: TokenId, amount: bigint, options: TransactionOptions) => {
      if (!user) {
        throw new Error('Not authenticated')
      }

      const subaccount = subaccounts[subaccountId]
      if (!subaccount) {
        throw new Error('User does not own subaccount')
      }

      const { sessionKey, user: newUser } = await tryEnableTradingAccount({
        ...options,
        skipCompleteStatus: true,
      })

      options.onTransactionStatusChange('in-progress', {
        title: `Transferring ${formatTokenBalance(amount, token)}`,
        context: `Transferring ${formatTokenSymbol(token)} into your trading account.`,
      })

      const market = coerce(MarketId, subaccount.currency)
      if (subaccount.margin_type === 'PM' && !market) {
        throw new Error('Unsupported base currency for PM subaccount')
      }
      const signatureExpirySec = getUtcTimestampSec() + SECONDS_IN_HOUR
      const params: DepositSubaccountParams =
        subaccount.margin_type === 'PM'
          ? {
              subaccountId,
              marginType: 'PM',
              market: market as MarketId,
              signatureExpirySec,
            }
          : {
              subaccountId,
              marginType: 'SM',
              market: undefined,
              signatureExpirySec,
            }

      const depositParams = await signDeposit(user.address, sessionKey, token, amount, params)

      const sessionKeyHeaders = await signSessionKeyHeaders(newUser.address, sessionKey)
      const res = await depositApi(depositParams, sessionKeyHeaders)

      const txHash = await waitForTransaction(res.result.transaction_id)

      await Promise.all([mutate(), mutateBalances()])

      if (!options.skipCompleteStatus) {
        options.onTransactionStatusChange('complete', {
          txHash,
          chain: lyraChain,
          title: `Successfully Transferred ${formatTokenBalance(amount, token)}`,
          context: `Your ${formatTokenSymbol(token)} is ready for trading.`,
        })
      }

      return txHash
    },
    [user, subaccounts, tryEnableTradingAccount, getUtcTimestampSec, mutate, mutateBalances]
  )

  const submitOrder = useCallback(
    async (
      ticker: PartialTickerForSigning,
      params: OrderParamsWithoutSignature,
      options?: { disableToast?: boolean }
    ): Promise<Order> => {
      if (!user) {
        throw new Error('Not authenticated')
      }
      if (!subaccounts[params.subaccount_id]) {
        throw new Error('User does not own subaccount')
      }
      if (!sessionKey) {
        throw new Error('No session key')
      }

      if (!params.signature_expiry_sec) {
        // Default orders signature expiry to 30 days
        params.signature_expiry_sec = getUtcTimestampSec() + SECONDS_IN_DAY * 30
      }

      const signedParams = await signOrder(user.address, sessionKey, ticker, params)

      const sessionKeyHeaders = await signSessionKeyHeaders(user.address, sessionKey)

      const {
        result: { order },
      } = await sendOrder(signedParams, sessionKeyHeaders)
      const parsedParamsInstrument = parseInstrumentName(params.instrument_name)
      const market = parsedParamsInstrument?.marketId
      const icon = market ? (
        <MarketIcon marketId={parsedParamsInstrument.marketId} />
      ) : (
        <HelpCircle />
      )
      if (order.order_status === 'filled') {
        // filled order instantly, mutate subaccount to get open position
        await mutate()

        if (!options?.disableToast) {
          createToast({
            title: 'Order Filled',
            description: formatInstrumentWithSize(params.instrument_name, +order.amount, {
              showExpiry: true,
            }),
            icon,
          })
        }
      } else if (['open', 'untriggered'].includes(order.order_status)) {
        mutate(fetcher(user), {
          // // pending order, optimistically insert order into subaccount
          optimisticData: (subaccounts) =>
            subaccounts
              ? {
                  ...subaccounts,
                  [params.subaccount_id]: {
                    ...subaccounts[params.subaccount_id],
                    open_orders: [order, ...subaccounts[params.subaccount_id].open_orders],
                  },
                }
              : {},
        })

        createToast({
          title: 'Order Submitted',
          description: formatInstrumentWithSize(params.instrument_name, +order.amount, {
            showExpiry: true,
          }),
          icon,
        })
      } else if (order.order_status === 'cancelled' && +order.filled_amount > 0) {
        // order status cancelled with partial fill, mutate to get open position
        await mutate()
        createToast({
          title: 'Order Partially Filled',
          description: formatInstrumentWithSize(params.instrument_name, +order.amount, {
            showExpiry: true,
          }),
          icon,
        })
      }
      const parsedInstrument = parseInstrumentName(order.instrument_name)
      recordSpindlEvent(
        'order',
        {
          direction: order.direction,
          instrument_market: parsedInstrument?.marketId,
          instrument_name: order.instrument_name,
          instrument_type: parsedInstrument?.type,
          mark_price: order.average_price,
          market_maker: false,
          notional_volume: +order.amount * +order.limit_price,
          subaccount_id: params.subaccount_id,
          trade_amount: order.amount,
          trade_fee: order.order_fee,
          order_id: order.order_id,
          trade_price: order.limit_price,
        },
        {
          address: user.address,
          ownerAddress: user.ownerAddress,
        }
      )
      return order
    },
    [user, subaccounts, sessionKey, getUtcTimestampSec, mutate, createToast]
  )

  const submitAuctionBid = useCallback(
    async (
      subaccountId: number,
      cashTransfer: string,
      lastSeenTradeId: number,
      liquidatedSubaccountId: number,
      percentBid: string,
      priceLimit: string
    ): Promise<PrivateLiquidateResultSchema> => {
      if (!user) {
        throw new Error('Not authenticated')
      }
      if (!sessionKey) {
        throw new Error('No session key')
      }

      const signedAction = await signLiquidateQuote(
        user.address,
        sessionKey,
        subaccountId,
        liquidatedSubaccountId,
        priceLimit,
        percentBid,
        cashTransfer,
        lastSeenTradeId
      )

      const sessionKeyHeaders = await signSessionKeyHeaders(user.address, sessionKey)

      const params: PrivateLiquidateParamsSchema = {
        cash_transfer: cashTransfer,
        last_seen_trade_id: lastSeenTradeId,
        liquidated_subaccount_id: liquidatedSubaccountId,
        nonce: signedAction.nonce,
        percent_bid: percentBid,
        price_limit: priceLimit,
        signature: signedAction.signature,
        signature_expiry_sec: signedAction.signatureExpiry,
        signer: signedAction.signer,
        subaccount_id: subaccountId,
      }

      const { result } = await liquidate(params, sessionKeyHeaders)
      createToast({
        title: 'Bid Submitted',
        description: 'Your bid has been submitted',
      })
      return result
    },
    [user, sessionKey, createToast]
  )

  // TODO: @earthtojake specify subaccount ID in cancelOrder
  const cancelOrder = useCallback(
    async (
      orderId: string,
      instrumentName: string,
      options?: { disableToast?: boolean }
    ): Promise<PrivateCancelResultSchema> => {
      if (!user) {
        throw new Error('Not authenticated')
      }
      if (!selectedSubaccountId) {
        throw new Error('No subaccount')
      }
      if (!sessionKey) {
        throw new Error('No session key')
      }

      const params: PrivateCancelParamsSchema = {
        order_id: orderId,
        instrument_name: instrumentName,
        subaccount_id: selectedSubaccountId,
      }
      console.log('Cancel order', { params })

      const sessionKeyHeaders = await signSessionKeyHeaders(user.address, sessionKey)
      const { result: cancelRes } = await sendCancel(params, sessionKeyHeaders)

      mutate(fetcher(user), {
        // optimistically cancel order
        optimisticData: (subaccounts) =>
          subaccounts
            ? {
                ...subaccounts,
                [params.subaccount_id]: {
                  ...subaccounts[params.subaccount_id],
                  open_orders: [...subaccounts[params.subaccount_id].open_orders].filter(
                    (order) => order.order_id !== orderId
                  ),
                },
              }
            : {},
      })

      if (!options?.disableToast) {
        const parsedInstrument = parseInstrumentName(params.instrument_name)
        createToast({
          title: 'Order Cancelled',
          description: formatInstrumentWithSize(params.instrument_name, +cancelRes.amount, {
            showExpiry: true,
          }),
          icon: parsedInstrument?.marketId ? (
            <MarketIcon marketId={parsedInstrument.marketId} />
          ) : (
            <HelpCircle />
          ),
          color: 'red',
        })
      }

      return cancelRes
    },
    [user, selectedSubaccountId, sessionKey, mutate, createToast]
  )

  const cancelTriggerOrder = useCallback(
    async (orderId: string, instrumentName: string, options?: { disableToast?: boolean }) => {
      if (!user) {
        throw new Error('Not authenticated')
      }
      if (!selectedSubaccountId) {
        throw new Error('No subaccount')
      }
      if (!sessionKey) {
        throw new Error('No session key')
      }

      const params: PrivateCancelTriggerOrderParamsSchema = {
        order_id: orderId,
        subaccount_id: selectedSubaccountId,
      }

      const sessionKeyHeaders = await signSessionKeyHeaders(user.address, sessionKey)

      const { result: cancelRes } = await sendCancelTriggerOrder(params, sessionKeyHeaders)

      mutate()

      if (!options?.disableToast) {
        const parsedInstrument = parseInstrumentName(instrumentName)
        createToast({
          title: 'Order Cancelled',
          description: formatInstrumentWithSize(instrumentName, +cancelRes.amount, {
            showExpiry: true,
          }),
          icon: parsedInstrument?.marketId ? (
            <MarketIcon marketId={parsedInstrument.marketId} />
          ) : (
            <HelpCircle />
          ),
          color: 'red',
        })
      }

      return cancelRes
    },
    [createToast, mutate, selectedSubaccountId, sessionKey, user]
  )

  const sendRfq = useCallback(
    async (params: PrivateSendRfqParamsSchema): Promise<PrivateSendRfqResultSchema> => {
      if (!user) {
        throw new Error('Not authenticated')
      }

      const authHeaders = getAuthHeaders(user)

      const { result } = await fetchSendRfq(params, authHeaders)
      console.debug('sendRfq', params)

      return result
    },
    [user]
  )

  const sendRfqQuote = useCallback(
    async (
      tickers: Record<string, PartialTickerForSigning>,
      params: PrivateSendQuoteParamsSchemaNoSignature
    ): Promise<PrivateSendQuoteResultSchema> => {
      if (!user || !sessionKey || !subaccounts[params.subaccount_id]) {
        throw new Error('Not authenticated')
      }

      const sessionKeyHeaders = await signSessionKeyHeaders(user.address, sessionKey)

      const signedParams = await signSendQuote(user.address, sessionKey, tickers, params)

      const res = await fetchSendQuote(signedParams, sessionKeyHeaders)
      console.debug('sendQuote', params)

      return res.result
    },
    [sessionKey, subaccounts, user]
  )

  const cancelRfqQuote = useCallback(
    async (params: PrivateCancelQuoteParamsSchema): Promise<PrivateCancelQuoteResultSchema> => {
      if (!user || !sessionKey || !subaccounts[params.subaccount_id]) {
        throw new Error('Not authenticated')
      }

      const sessionKeyHeaders = await signSessionKeyHeaders(user.address, sessionKey)

      const res = await fetchCancelQuote(params, sessionKeyHeaders)
      return res.result
    },
    [sessionKey, subaccounts, user]
  )

  const executeRfq = useCallback(
    async (
      tickers: Record<string, PartialTickerForSigning>,
      params: PrivateExecuteQuoteParamsSchemaNoSignature
    ): Promise<PrivateExecuteQuoteResultSchema> => {
      if (!user) {
        throw new Error('Not authenticated')
      }
      if (!subaccounts[params.subaccount_id]) {
        throw new Error('User does not own subaccount')
      }
      if (!sessionKey) {
        throw new Error('No session key')
      }

      const signedParams = await signRfqQuote(user.address, sessionKey, tickers, params)

      const sessionKeyHeaders = await signSessionKeyHeaders(user.address, sessionKey)
      const { result } = await fetchExecuteRfq(signedParams, sessionKeyHeaders)

      if (result.status === 'filled') {
        await mutate()
        const descriptions = params.legs.map((leg) => {
          return formatInstrumentWithSize(leg.instrument_name, +leg.amount, {
            showExpiry: true,
          })
        })

        // TODO: @earthtojake update to support rfq across multiple markets
        const marketId = parseMarketFromInstrumentName(params.legs[0].instrument_name)
        createToast({
          title: 'Order Filled',
          description: (
            <YStack>
              {descriptions.map((desc, index) => (
                <BodyText color="secondary" key={index}>
                  {desc}
                </BodyText>
              ))}
            </YStack>
          ),
          icon: marketId ? <MarketIcon marketId={marketId} /> : null,
        })
      }
      return result
    },
    [user, subaccounts, sessionKey, mutate, createToast]
  )

  const cancelRfq = useCallback(
    async (params: PrivateCancelRfqParamsSchema): Promise<Result> => {
      if (!user) {
        throw new Error('Not authenticated')
      }

      const authHeaders = getAuthHeaders(user)
      const { result } = await fetchCancelRfq(params, authHeaders)
      console.debug('cancelRfq', params.rfq_id, params.subaccount_id)

      return result
    },
    [user]
  )

  const withdraw = useCallback(
    async (
      subaccountId: number,
      collateral: CollateralId,
      amount: bigint,
      options: TransactionOptions
    ) => {
      if (!user) {
        throw new Error('Not authenticated')
      }

      if (!subaccounts[subaccountId]) {
        throw new Error('User does not own subaccount')
      }

      const { sessionKey } = await tryEnableTradingAccount({
        ...options,
        skipCompleteStatus: true,
      })
      const decimals = getTokenDecimals(getTokenForCollateral(collateral))
      const amountStr = bigNumberToString(amount, decimals)

      console.debug('withdraw', {
        sessionKey: sessionKey.address,
        subaccountId,
        collateral,
        amount,
        decimals,
        options,
      })

      options.onTransactionStatusChange('in-progress', {
        title: 'Transferring Collateral',
        context: `Transferring ${formatCollateralBalance(
          +amountStr,
          collateral
        )} from your trading account.`,
      })

      const collateralAddress = getCollateralAddress(collateral)
      const encodedWithdrawData = encodeAbiParameters(
        [{ type: 'address' }, { type: 'uint' }],
        [collateralAddress, amount]
      )

      const { signature, signatureExpiry, signer, nonce } = await signAction({
        chainId: lyraChain.id,
        signingAccount: sessionKey,
        owner: user.address,
        subaccountId,
        encodedData: encodedWithdrawData,
        contract: 'withdraw',
      })

      const apiParams = {
        amount: amountStr,
        asset_name: collateral,
        nonce,
        signature,
        signature_expiry_sec: signatureExpiry,
        signer,
        subaccount_id: subaccountId,
      }

      const headers = await signSessionKeyHeaders(user.address, sessionKey)
      const res = await withdrawApi(apiParams, headers)

      const txHash = await waitForTransaction(res.result.transaction_id)
      await Promise.all([mutate(), mutateBalances()])

      if (!options.skipCompleteStatus) {
        options.onTransactionStatusChange('complete', {
          txHash,
          chain: lyraChain,
          title: 'Successfully Transferred Collateral',
        })
      }

      return txHash
    },
    [tryEnableTradingAccount, mutate, mutateBalances, subaccounts, user]
  )

  const switchSubaccount = useCallback(
    (subaccountId: number) => {
      if (!user) {
        throw new Error('Not authenticated')
      }

      if (subaccountId === selectedSubaccountId) {
        return
      }
      if (!subaccounts[subaccountId]) {
        throw new Error('User does not own subaccount')
      }

      setLocalSubaccountId(subaccountId.toString())

      mutate()
    },
    [mutate, selectedSubaccountId, setLocalSubaccountId, subaccounts, user]
  )

  const transfer = useCallback(
    async (
      senderSubaccountId: number,
      receiverSubaccountId: number,
      collateral: CollateralId,
      amount: string,
      options: TransactionOptions
    ) => {
      if (!user) {
        throw new Error('Not authenticated')
      }

      if (!subaccounts[senderSubaccountId]) {
        throw new Error('User does not own sender subaccount')
      }
      if (!subaccounts[receiverSubaccountId]) {
        throw new Error('User does not own receiver subaccount')
      }

      const { sessionKey } = await tryEnableTradingAccount({
        ...options,
        skipCompleteStatus: true,
      })

      options.onTransactionStatusChange('in-progress', {
        title: `Transferring ${formatCollateralBalance(+amount, collateral)}`,
      })

      // Fetch Assets for collateral
      const assetResponse = await fetchAssets({
        asset_type: 'erc20',
        currency: collateral,
        expired: false,
      })
      const collateralAsset = assetResponse.result[0]

      // Encode data for sender sign action
      const newManager = NEW_MANAGER_ADDRESS
      const senderTransferData = [
        receiverSubaccountId,
        newManager,
        [[collateralAsset.address, collateralAsset.sub_id, toBigNumber(amount, WEI_DECIMALS)]], // NOTE: Even if this is USDC it's still 18 decimals
      ]
      const TransferDataABI = ['(uint256,address,(address,uint256,int256)[])']
      const encoder = ethers.AbiCoder.defaultAbiCoder()
      const senderEncodedData = encoder.encode(TransferDataABI, [senderTransferData])

      // Sender sign action
      const signatureExpirySec = getUtcNowSecs() + SECONDS_IN_HOUR
      const senderSignResult = await signAction({
        chainId: lyraChain.id,
        signingAccount: sessionKey,
        owner: user.address,
        subaccountId: senderSubaccountId,
        encodedData: senderEncodedData as `0x${string}`,
        contract: 'transfer',
        signatureExpiry: signatureExpirySec,
      })

      // Receiver sign action
      const encodedDataForReceiver = '0x'
      const receiverSignResult = await signAction({
        chainId: lyraChain.id,
        signingAccount: sessionKey,
        owner: user.address,
        subaccountId: receiverSubaccountId,
        encodedData: encodedDataForReceiver,
        contract: 'transfer',
        signatureExpiry: signatureExpirySec,
      })

      // Execute transfer
      const sessionKeyHeaders = await signSessionKeyHeaders(user.address, sessionKey)

      const res = await transferERC20(
        {
          recipient_details: {
            nonce: receiverSignResult.nonce,
            signature: receiverSignResult.signature,
            signature_expiry_sec: receiverSignResult.signatureExpiry,
            signer: receiverSignResult.signer,
          },
          recipient_subaccount_id: receiverSubaccountId,
          sender_details: {
            nonce: senderSignResult.nonce,
            signature: senderSignResult.signature,
            signature_expiry_sec: senderSignResult.signatureExpiry,
            signer: senderSignResult.signer,
          },
          subaccount_id: senderSubaccountId,
          transfer: {
            address: getCollateralAddress(collateral),
            amount,
            sub_id: +collateralAsset.sub_id,
          },
        },
        sessionKeyHeaders,
        { method: 'POST' }
      )

      const txId = res.result.transaction_id

      const txHash = await waitForTransaction(txId)

      options.onTransactionStatusChange('complete', {
        title: `Successfully Transferred ${formatCollateralBalance(+amount, collateral)}`,
      })

      return txHash
    },
    [tryEnableTradingAccount, subaccounts, user]
  )

  const transferPositionToSubaccount = useCallback(
    async (
      senderSubaccountId: number,
      receiverSubaccountId: number,
      instrumentName: string,
      amount: string,
      isLong: boolean,
      limitPrice: string,
      ticker: PartialTickerForSigning,
      options: TransactionOptions
    ) => {
      if (!user) {
        throw new Error('Not authenticated')
      }

      if (!subaccounts[senderSubaccountId]) {
        throw new Error('User does not own sender subaccount')
      }

      if (!subaccounts[receiverSubaccountId]) {
        throw new Error('User does not own receiver subaccount')
      }

      const { sessionKey } = await tryEnableTradingAccount({
        ...options,
        skipCompleteStatus: true,
      })

      const sessionKeyHeaders = await signSessionKeyHeaders(user.address, sessionKey)
      const signatureExpirySec = getUtcNowSecs() + SECONDS_IN_HOUR

      const senderParams: OrderParamsWithoutSignature = {
        amount: amount,
        direction: isLong ? 'sell' : 'buy',
        instrument_name: instrumentName,
        limit_price: limitPrice,
        max_fee: '0',
        signature_expiry_sec: signatureExpirySec,
        subaccount_id: senderSubaccountId,
      }

      const receiverParams: OrderParamsWithoutSignature = {
        amount: amount,
        direction: isLong ? 'buy' : 'sell',
        instrument_name: instrumentName,
        limit_price: limitPrice,
        max_fee: '0',
        signature_expiry_sec: signatureExpirySec,
        subaccount_id: receiverSubaccountId,
      }

      const senderSignResult = await signOrder(user.address, sessionKey, ticker, senderParams)

      const receiverSignResult = await signOrder(user.address, sessionKey, ticker, receiverParams)

      const transferParams: PrivateTransferPositionParamsSchema = {
        maker_params: {
          ...senderSignResult,
        },
        taker_params: {
          ...receiverSignResult,
        },
        wallet: user.address,
      }

      await transferPosition(transferParams, sessionKeyHeaders, { method: 'POST' })
    },
    [user, subaccounts, tryEnableTradingAccount]
  )

  const mintTestnetUsdcAndDeposit = useCallback(
    async (subaccountId: number, options: TransactionOptions) => {
      options.onTransactionStatusChange('in-progress', {
        title: 'Minting Testnet USDC',
      })
      const balance = await mintTestnetUsdc()

      if (balance === BigInt(0)) {
        throw new Error('Failed to mint testnet USDC')
      }

      return await deposit(subaccountId, TokenId.USDC, balance, options)
    },
    [deposit, mintTestnetUsdc]
  )

  const replaceOrder = useCallback(
    async (
      orderIdToCancel: string,
      ticker: PartialTickerForSigning,
      params: OrderParamsWithoutSignature
    ): Promise<PrivateReplaceResultSchema> => {
      if (!user) {
        throw new Error('Not authenticated')
      }
      if (!sessionKey) {
        throw new Error('No session key')
      }

      const sessionKeyHeaders = await signSessionKeyHeaders(user.address, sessionKey)

      const signedParams = await signOrder(user.address, sessionKey, ticker, params)

      const replaceParams: PrivateReplaceParamsSchema = {
        ...signedParams,
        order_id_to_cancel: orderIdToCancel,
      }

      const { result } = await replace(replaceParams, sessionKeyHeaders)
      await mutate()

      const parsedInstrument = parseInstrumentName(params.instrument_name)
      const icon = parsedInstrument?.marketId ? (
        <MarketIcon marketId={parsedInstrument.marketId} />
      ) : (
        <HelpCircle />
      )

      if (result.create_order_error) {
        createToast({
          title: 'Order Failed To Update',
          description: formatInstrumentWithSize(params.instrument_name, +params.amount, {
            showExpiry: true,
          }),
          icon,
          color: 'red',
        })
      } else {
        createToast({
          title: 'Order Updated',
          description: formatInstrumentWithSize(params.instrument_name, +params.amount, {
            showExpiry: true,
          }),
          icon,
        })
      }

      return result
    },
    [createToast, mutate, sessionKey, user]
  )

  /**
   * LISTENERS
   */

  const value = useMemo(() => {
    return {
      // TODO: @earthtojake tidy up subaccountData export
      ...(subaccountData ?? EMPTY_SUBACCOUNT_DATA),
      subaccountData,
      totalAccountValue,
      totalUsdcBalance,
      subaccounts,
      subaccountDatas,
      isLoading,
      createSubaccount,
      updateLabel,
      switchSubaccount,
      deposit,
      withdraw,
      submitOrder,
      submitAuctionBid,
      cancelOrder,
      cancelTriggerOrder,
      sendRfq,
      sendRfqQuote,
      cancelRfqQuote,
      cancelRfq,
      executeRfq,
      transfer,
      transferPositionToSubaccount,
      mintTestnetUsdcAndDeposit,
      replaceOrder,
      mutate,
    }
  }, [
    isLoading,
    totalAccountValue,
    totalUsdcBalance,
    subaccountData,
    subaccounts,
    subaccountDatas,
    createSubaccount,
    updateLabel,
    switchSubaccount,
    deposit,
    withdraw,
    submitOrder,
    submitAuctionBid,
    cancelOrder,
    cancelTriggerOrder,
    sendRfq,
    sendRfqQuote,
    cancelRfqQuote,
    cancelRfq,
    executeRfq,
    transfer,
    transferPositionToSubaccount,
    mintTestnetUsdcAndDeposit,
    replaceOrder,
    mutate,
  ])

  return <SubaccountContext.Provider value={value}>{children}</SubaccountContext.Provider>
}
