import React, { SVGProps } from 'react'

export default function QuestionPixelIcon({ color, ...props }: SVGProps<SVGSVGElement>) {
  return (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
    >
      <g id="Pixel/Solid/Question">
        <path
          id="Vector"
          d="M18 5V11H17V12H15V13H14V15H9V12H10V11H11V10H13V9H14V7H13V6H11V7H10V8H9V9H8V8H7V7H6V6H5V5H6V4H7V3H9V2H15V3H16V4H17V5H18Z"
          fill={color}
        />
        <path id="Vector_2" d="M13 18H14V21H13V22H10V21H9V18H10V17H13V18Z" fill={color} />
      </g>
    </svg>
  )
}
